.rounded-10px {
  border-radius: 10px !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.gap-2 {
  gap: 8px !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.relative {
  position: relative !important;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.break-word {
  word-break: break-word;
}