// import fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

@import url('./customMui.scss');
@import url('./style.css');
@import url('./components.scss');
@import url('./space.scss');
@import url('./sizing.scss');
@import url('./text.scss');
@import url('./ultilities.scss');

html {
  font-family: 'Noto Sans JP', sans-serif;
  color: #2b2e2f;
}

input,
textarea {
  outline: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table > tbody > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #ddd;
}
