.px-0 {
  padding-inline: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-25px {
  padding-bottom: 25px;
}

.py-0 {
  padding-block: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.mx-4 {
  margin-inline: 16px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.my-6 {
  margin-block: 24px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mx-auto {
  margin-inline: auto !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}
