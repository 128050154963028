.Mui-checked {
  color: #245faf !important;
}

.MuiCheckbox-root {
  padding: 0 !important;
}

.MuiButtonBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.MuiAccordion-root::before {
  display: none;
}
