.text-13px {
  font-size: 13px !important;
}

.text-base {
  font-size: 16px !important;
}

.text-primary {
  color: #04b2d9 !important;
}

.bg-primary {
  background-color: #04b2d9 !important;
}

.text-gray_1 {
  color: #8e8e8e !important;
}

.text_gray_2 {
  color: #98a6b5 !important;
}

.bg_gray_2 {
  background-color: #98a6b5 !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
