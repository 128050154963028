// add "*" for required input
.required {
  position: relative;
  width: fit-content;
  display: inline-block;

  &:after {
    content: '＊';
    position: absolute;
    color: #f65e5e;
    margin-left: 4px;
  }
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.back-btn {
  position: absolute;
  top: 16px;
  left: 8px;
}

.block-ui {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-content {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.editor-content {
  overflow-wrap: break-word;

  & * {
    max-width: 100%;
    margin-inline: 0 !important;
  }

  & p {
    margin-bottom: 16px;
  }

  & a,
  .a-tag {
    color: #bdbdc7;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: #593bdb !important;
    }
  }

  & dt {
    font-weight: 700;
  }

  & dd {
    margin-bottom: 8px;
  }
}

.editor-wrapper {
  &:not(.mobile) {
    height: calc(100vh - 133px);
  }

  &.mobile {
    height: calc(100vh - 48px);
  }

  & .editor-content {
    height: 100%;
    overflow: auto;
    padding-right: 8px;
  }
}

.accordion {
  box-shadow: none !important;
  border-bottom: 1px solid #c6d3e1 !important;
  border-radius: 0 !important;
  margin-right: 8px !important;
}

.form-item-select {
  border: 1px solid #98a6b5 !important;
  border-radius: 0 !important;
}

.bl_tab_item.selected {
  border-bottom: 3px solid #245faf;
  font-weight: 700;
}

.faq-content {
  // open in browser
  &:not(.mobile) {
    height: calc(100vh - 133px);

    &.loading-more {
      // loading spinner 46px
      height: calc(100vh - 133px - 46px);
    }
  }

  // open in mobile device
  &.mobile {
    height: calc(100vh - 48px);

    &.loading-more {
      // loading spinner 46px
      height: calc(100vh - 48px - 46px);
    }
  }
}

.notification-content {
  // open in browser
  &:not(.mobile) {
    height: calc(100vh - 173px);

    &.loading-more {
      // loading spinner 46px
      height: calc(100vh - 173px - 46px);
    }
  }

  // open in mobile device
  &.mobile {
    height: calc(100vh - 88px);

    &.loading-more {
      // loading spinner 46px
      height: calc(100vh - 88px - 46px);
    }
  }
}

.notification-detail-content {
  display: flex;
  flex-direction: column;

  &:not(.mobile) {
    height: calc(100vh - 133px);
  }

  &.mobile {
    height: calc(100vh - 48px);
  }
}

.step-bar {
  width: 200px;
  height: 1px;
  position: relative;
  background-color: #c6d3e1;
}

.text_step {
  position: absolute;
  font-size: 12px;
  font-weight: 500;

  &.step_1 {
    left: -8px;
    top: -24px;
  }

  &.step_2 {
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
  }

  &.step_3 {
    right: -8px;
    top: -24px;
  }
}

.step {
  position: absolute;
  border-radius: 9999px;
  width: 8px;
  height: 8px;

  &.step_1 {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.step_2 {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.step_3 {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1200;
}

.loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #98a6b5;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: spinner 1s infinite steps(8);
}
@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

.loader-input-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 14px;
  height: 14px;
}

.loader-input {
  width: 100%;
  height: 100%;
  border: 2px solid #a5a5a5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-sticky-bottom {
  position: sticky;
  bottom: 24px;
}
