@layer {
  html {
    box-sizing: border-box;
    /* overflow-y: scroll; */
    -webkit-text-size-adjust: 100%;
  }
  *,
  ::before,
  ::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
  }
  ::before,
  ::after {
    text-decoration: inherit;
    vertical-align: inherit;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  hr {
    overflow: visible;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  summary {
    display: list-item;
  }
  small {
    font-size: 80%;
  }
  [hidden],
  template {
    display: none;
  }
  abbr[title] {
    border-bottom: 1px dotted;
    text-decoration: none;
  }
  a {
    background-color: rgba(0, 0, 0, 0);
    -webkit-text-decoration-skip: objects;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
  }
  dfn {
    font-style: italic;
  }
  mark {
    background-color: #ff0;
    color: #000;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  li {
    list-style: none;
  }
  table,
  table th,
  table td {
    border-spacing: 0;
  }
  input {
    border-radius: 0;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'],
  [role='button'] {
    cursor: pointer;
  }
  [disabled] {
    cursor: default;
  }
  [type='number'] {
    width: auto;
  }
  [type='search'] {
    -webkit-appearance: textfield;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
  }
  optgroup {
    font-weight: bold;
  }
  button {
    overflow: visible;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: 0;
    padding: 0;
  }
  button:-moz-focusring,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    outline: 1px dotted ButtonText;
  }
  button,
  html[type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  input,
  select,
  textarea {
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    color: inherit;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  select::-ms-value {
    color: currentColor;
  }
  legend {
    border: 0;
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  img {
    border-style: none;
  }
  progress {
    vertical-align: baseline;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
  @media screen {
    [hidden~='screen'] {
      display: inherit;
    }
    [hidden~='screen']:not(:active):not(:focus):not(:target) {
      position: absolute !important;
      clip: rect(0 0 0 0) !important;
    }
  }
  [aria-busy='true'] {
    cursor: progress;
  }
  [aria-controls] {
    cursor: pointer;
  }
  [aria-disabled] {
    cursor: default;
  }
  ::-moz-selection {
    background-color: #b3d4fc;
    color: #000;
    text-shadow: none;
  }
  ::selection {
    background-color: #b3d4fc;
    color: #000;
    text-shadow: none;
  }
}
@layer {
  :root {
    scroll-behavior: smooth;
  }
  html:has(dialog[open]) {
    overflow: hidden;
  }
  body {
    font-family: 'Noto Sans JP', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 14px;
    color: #2b2e2f;
    background: #fff;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  textarea,
  input[type='text'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  input[type='email'],
  input[type='datetime'],
  input[type='date'],
  input[type='month'],
  input[type='week'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='number'],
  input[type='password'] {
    padding: 0.5em;
    width: 100%;
  }
  textarea {
    min-height: 10em;
    max-height: 25em;
  }
  ::-moz-placeholder {
    color: gray;
  }
  ::placeholder {
    color: gray;
  }
}
@page {
  margin: 3mm;
  size: A4 portrait;
}
@font-face {
  font-family: 'icon';
  src: url('../fonts/icon.ttf?aks5uq') format('truetype'),
    url('../fonts/icon.woff?aks5uq') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='el_icm_'],
[class*=' el_icm_'] {
  font-family: 'icon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el_icm_arrowL::before {
  display: block;
  content: '\e900';
}
.el_icm_arrowR::before {
  display: block;
  content: '\e901';
}
.el_icm_arrowU::before {
  display: block;
  content: '\e902';
}
.el_icm_arrowD::before {
  display: block;
  content: '\e903';
}
.el_icm_car::before {
  display: block;
  content: '\e904';
}
.el_icm_credit::before {
  display: block;
  content: '\e905';
}
.el_icm_user::before {
  display: block;
  content: '\e906';
}
.el_icm_load::before {
  display: block;
  content: '\e907';
}
.ly_container {
  margin: 24px auto;
  padding: 0 0;
  width: calc(min(92%, 780px));
}
.ly_container_wide {
  width: 100%;
  max-width: 780px;
  padding: 16px 4%;
}
@media print {
  .ly_receipt {
    margin: 0 auto;
    padding: 10mm 15mm;
    border: 1px solid #707070;
    max-width: 90%;
  }
}
.ly_receipt_inner {
  margin: 50px auto;
  max-width: 310px;
}
@media print {
  .ly_receipt_inner {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
  }
}
.ly_footer {
  text-align: center;
  margin-top: 40px;
  padding: 20px 0;
}
.bl_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  -moz-column-gap: 20px;
  column-gap: 20px;
  row-gap: 5px;
  align-items: flex-start;
}
.bl_flex {
  display: flex;
}
.bl_flex.justify_between {
  justify-content: space-between;
}
.bl_flex.justify_center {
  justify-content: center;
}
.bl_flex.justify_end {
  justify-content: flex-end;
}
.bl_flex.align_stretch {
  align-items: stretch;
}
.bl_flex.align_center {
  align-items: center;
}
.bl_flex.align_start {
  align-items: flex-start;
}
.bl_pageTitle {
  padding: 24px 0 0;
  text-align: center;
  background: #f8f8f8;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.bl_radiusBox {
  border-radius: 8px;
  padding: 16px;
}
.bl_loading_loader {
  margin: auto;
}
.bl_receiptPrice {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  -moz-column-gap: 0.3em;
  column-gap: 0.3em;
  line-height: 1;
  font-weight: 700;
  font-size: 28px;
  padding: 0.5em 0;
  border-bottom: 1px solid #707070;
}
.bl_receiptPrice_before {
  font-size: 24px;
  font-weight: 500;
}
.bl_receiptPrice_after {
  font-size: 20px;
}
.bl_receiptFooter {
  display: table;
  margin-left: auto;
}
.bl_form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.bl_form_head {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
}
.bl_form_ttl {
  font-weight: 400;
}
.bl_form_body {
  margin-bottom: 15px;
}
.el_btn_common {
  padding: 0.8em !important;
  font-weight: 600 !important;
  color: #fff !important;
  text-align: center;
  border-radius: 5em !important;
  background: #04b2d9 !important;
  width: 100%;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  max-width: 500px;
}
.el_btn_common[disabled] {
  background: #c6d3e1 !important;
  color: #fff !important;
}
a.el_btn_common {
  display: inline-block;
}
.el_btn_border {
  padding: 0.8em;
  width: 100%;
  max-width: 500px;
  font-weight: 600;
  text-align: center;
  border-radius: 5em;
  border: 1px solid #2b2e2f;
}
.el_btn_incIcon {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.el_btn_mini {
  padding: 0.3em 1em;
  border: 1px solid #c6d3e1;
  background: #fff;
  border-radius: 5em;
  font-size: 12px;
  font-weight: 600;
}
.el_btn_circle {
  width: 30px;
  height: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background: #fff;
  color: #4c4c4c;
}
@keyframes spinner-1 {
  0% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      50% 0%,
      50% 0%,
      50% 0%,
      50% 0%
    );
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    -webkit-clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    -webkit-clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    -webkit-clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes spinner-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
.el_icn_loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid #b6c0ca;
  animation: spinner-1 0.8s infinite linear alternate,
    spinner-2 1.6s infinite linear;
}
.el_txt_white {
  color: #fff;
}
.el_txt_black {
  color: #2b2e2f;
}
.el_txt_red {
  color: #f65e5e;
}
.el_txt_gray {
  color: #98a6b5;
}
.el_txt_link {
  color: #245faf;
  text-decoration: underline;
}
.el_txt_paragraph {
  line-height: 1.6;
  font-size: inherit;
}
.el_txt_paragraph14 {
  line-height: 1.4;
  font-size: inherit;
}
.el_txt_paragraph17 {
  line-height: 1.71428;
  font-size: inherit;
}
.el_txt_bold400 {
  font-weight: 400;
}
.el_txt_bold500 {
  font-weight: 500;
}
.el_txt_bold600 {
  font-weight: 600;
}
.el_txt_bold700 {
  font-weight: 700;
}
.el_txt_bold800 {
  font-weight: 800;
}
.el_txt_space2 {
  letter-spacing: 0.02em;
}
.el_txt_space5 {
  letter-spacing: 0.05em;
}
.el_txt_center {
  text-align: center;
}
.el_txt_left {
  text-align: left;
}
.el_txt_right {
  text-align: right;
}
.el_txt_xs {
  font-size: 10px;
}
.el_txt_s {
  font-size: 12px;
}
.el_txt_rg {
  font-size: 14px;
}
.el_txt_md {
  font-size: 16px;
}
.el_txt_lg {
  font-size: 18px;
}
.el_txt_xlg {
  font-size: 20px;
}
.el_txt_xxlg {
  font-size: 22px;
}
.el_txt_flex {
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
}
.el_txt_flex__beseline {
  align-items: baseline;
}
.el_txt_flex__justifyCenter {
  justify-content: center;
}
.el_txt_flex__wrap {
  flex-wrap: wrap;
}
.el_input {
  padding: 0.3em 0.5em;
  border: 1px solid #98a6b5;
}
.el_input__w60p {
  width: 60px;
}
.el_input__w115p {
  width: 115px;
}
.ut_bg_lightBlue .el_input {
  background-color: #fff;
}
.el_input_blank {
  padding: 0.5em 0;
}
.el_input_blank:focus {
  outline: none;
}
.el_input_blank::-moz-placeholder {
  opacity: 1;
  color: #f8f8f8;
}
.el_input_blank::placeholder {
  opacity: 1;
  color: #f8f8f8;
}
.el_form_required {
  color: #f65e5e;
  font-size: 12px;
  font-weight: 400;
}
.el_checkbox + .el_label {
  padding-left: 0.4em;
}
.bl_dialog_back {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
}
.bl_dialog {
  width: 93%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 28.33%;
}
.bl_dialog_textWrapper {
  border-bottom: solid 1px #dcdcdc;
  padding: 25px 22px 15px 22px;
  min-height: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bl_dialog_btnWrapper {
  display: flex;
  justify-content: space-between;
}
.bl_dialog_btn {
  color: #2a8ff4 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center;
  padding: 12px 0 !important;
  width: 100%;
}
.bl_dialog_btn__2Choices {
  width: 50%;
}
.bl_dialog_btn + .bl_dialog_btn {
  border-left: solid 1px #dcdcdc;
}
.bl_dialog_btn__black {
  color: #2b2e2f !important;
}
.bl_dialog_btn__red {
  color: #f65e5e !important;
}
.bl_accordion {
  border-bottom: 1px solid #c6d3e1;
  padding: 14px 0 10px;
}
.bl_accordion_heading {
  position: relative;
  display: block;
  cursor: pointer;
  padding-right: 26px;
}
.bl_accordion_heading::before {
  display: block;
  font-family: 'icon' !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: '\e903';
}
.bl_accordion_heading::-webkit-details-marker {
  display: none;
  position: relative;
}
.bl_accordion_heading::before {
  position: absolute;
  right: 0;
  color: #245faf;
  font-weight: 700;
  line-height: 1.48;
}
.bl_accordion[open] .bl_accordion_heading::before {
  transform: rotate(180deg);
}
.bl_accordion_contents {
  padding-bottom: 8px;
}
.bl_accordion[open] .bl_accordion_contents {
  animation: fadeIn 0.5s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.el_question {
  display: block;
  position: relative;
  padding-left: 28px;
}
.el_question::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #245faf;
}
.el_question::after {
  position: absolute;
  left: 4px;
  top: 2px;
  content: 'Q';
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}
.el_answer {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-top: 9px;
}
.el_answer::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f65e5e;
}
.el_answer::after {
  position: absolute;
  left: 6px;
  top: 3px;
  content: 'A';
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}
.el_shadow {
  padding: 6px 0 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
}
.bl_stepbar {
  margin-top: 64px;
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 186px;
  margin: auto;
}
.bl_stepbar li {
  position: relative;
  color: #98a6b5;
  text-align: center;
  padding-bottom: 12px;
}
.bl_stepbar li span::after {
  content: '';
  display: block;
  width: 88px;
  height: 2px;
  background-color: #98a6b5;
  position: absolute;
  bottom: 3px;
  left: 50%;
}
.bl_stepbar li.is_active {
  color: #245faf;
}
.bl_stepbar li.is_active span::before {
  background-color: #245faf;
}
.bl_stepbar li span {
  width: 100%;
  height: 100%;
  display: block;
  white-space: nowrap;
}
.bl_stepbar li span::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #98a6b5;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.bl_stepbar :first-child {
  text-align: left;
  margin-left: -4px;
}
.bl_stepbar :last-child {
  text-align: right;
  margin-right: -4px;
}
.bl_stepbar :last-child span::after {
  content: none;
}
.bl_borderList .bl_borderList_ttl {
  padding-top: 12px;
}
.bl_borderList .bl_borderList_body {
  padding-top: 5px;
  padding-bottom: 7px;
  border-bottom: solid 1px #c6d3e1;
  line-height: 1.714;
}
.bl_newsList > li {
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: solid 1px #c6d3e1;
  line-height: 1.5;
}
.bl_newsList > li:first-child {
  padding-top: 6px;
}
.bl_newsList a {
  display: block;
}
.bl_tab {
  width: 100%;
}
.bl_tabWrapper {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  height: 40px;
  display: flex;
  padding: 0 16px;
}
.bl_tab_item {
  width: 79px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  display: block;
  text-align: center;
  transition: all 0.2s ease;
}
.bl_tab_input {
  display: none;
}
.bl_tab_content {
  display: none;
}
.bl_tabWrapper:has(.ut_tab_input_news:checked) ~ .ut_tab_content_news,
.bl_tabWrapper:has(.ut_tab_input_your:checked) ~ .ut_tab_content_your {
  display: block;
}
.bl_tab input:checked + .bl_tab_item {
  border-bottom: 3px solid #245faf;
  font-weight: 700;
}
.ut_btn_contact_back {
  position: absolute;
  top: 21px;
  left: 15px;
}
.el_list_icn > li {
  margin-bottom: 0.5em;
}
.el_list_icn > li::before {
  content: '●';
  position: absolute;
  margin-top: 0.9em;
  margin-left: -1.7em;
  font-size: 0.5em;
  color: #245faf;
}
.el_list_icn li {
  position: relative;
  padding-left: 1em;
}
.el_list_icn li > ul,
.el_list_icn li > ol {
  margin-top: 0.5em;
}
.el_list_icn li:last-of-type {
  margin-bottom: 0;
}
.el_list_deci > li {
  margin-bottom: 0.5em;
  list-style: decimal;
}
.el_list_deci li {
  position: relative;
  margin-left: 1.5em;
}
.el_list_deci li:last-of-type {
  margin-bottom: 0;
}
.el_list_deci li > ul,
.el_list_deci li > ol {
  margin-top: 0.5em;
}
.el_list_dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.el_list_dl dt,
.el_list_dl dd {
  padding: 1.5em 0;
  border-bottom: 1px solid #f8f8f8;
}
.el_list_dl dt {
  width: 30%;
  font-weight: 500;
}
.el_list_dl dd {
  width: 70%;
  padding-left: 2em;
}
.el_ttl_page {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin: 0 0 24px;
}
.el_ttl_page.el_ttl_page__stiky {
  position: sticky;
  top: 0;
  padding-top: 24px;
  margin-top: -24px;
  padding-bottom: 24px;
  margin-bottom: 0;
  background-color: #fff;
}
.el_ttl_page_en {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #98a6b5;
  display: block;
}
.el_ttl_content {
  font-weight: 600;
  text-indent: -0.5em;
  font-size: 14px;
}
.el_ttl_username {
  font-weight: 600;
  font-size: 16px;
  color: #245faf;
  margin-bottom: 10px;
}
.el_ttl_mypage {
  margin: 0 auto;
  padding: 8px 0;
  width: calc(min(92%, 780px));
  font-size: 12px;
  color: #98a6b5;
}
.el_tbl_default {
  display: block;
  max-width: 96vw;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  border-collapse: collapse;
  border: 2px solid #f8f8f8;
}
.el_tbl_default tbody {
  width: 100%;
  display: table;
}
.el_tbl_default th,
.el_tbl_default td {
  padding: 1em;
  border-collapse: collapse;
  border: 1px solid #f8f8f8;
}
.el_tbl_default th {
  text-align: left;
  background: #81898c;
}
.ut_inlineBlock {
  display: inline-block;
}
.ut_block {
  display: block;
}
.ut_bg_gray {
  background-color: #f8f8f8;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
}
.ut_bg_white {
  background-color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.ut_bg_lightBlue {
  background-color: #f3fdff;
  border-top: 1px solid #f3f9ff;
  border-bottom: 1px solid #f3f9ff;
}
.ut_mt5 {
  margin-top: 5px !important;
}
.ut_mt10 {
  margin-top: 10px !important;
}
.ut_mt14 {
  margin-top: 14px !important;
}
.ut_mt20 {
  margin-top: 20px !important;
}
.ut_mt30 {
  margin-top: 30px !important;
}
.ut_mt45 {
  margin-top: 45px !important;
}
.ut_mt50 {
  margin-top: 50px !important;
}
.ut_mb5 {
  margin-bottom: 5px !important;
}
.ut_mb10 {
  margin-bottom: 10px !important;
}
.ut_mb20 {
  margin-bottom: 20px !important;
}
.ut_mb50 {
  margin-bottom: 50px !important;
}
.ut_ml8 {
  margin-left: 8px !important;
}
.ut_ml12 {
  margin-left: 12px !important;
}
.ut_ml20 {
  margin-left: 20px !important;
}
@layer editor {
  .bl_editor {
    overflow: hidden;
  }
  .bl_editor pre {
    white-space: pre-wrap;
  }
  .bl_editor h1,
  .bl_editor h2,
  .bl_editor h3,
  .bl_editor h4,
  .bl_editor h5,
  .bl_editor h6 {
    margin: 1.5em 0 0.5em;
    padding-left: 0.7em;
    font-weight: 600;
    border-left: 3px solid #98a6b5;
  }
  .bl_editor p {
    margin-bottom: 1em;
  }
  .bl_editor h1 {
    font-size: 20px;
  }
  .bl_editor h2 {
    font-size: 18px;
  }
  .bl_editor h3 {
    font-size: 17px;
  }
  .bl_editor h4 {
    font-size: 16px;
  }
  .bl_editor h5 {
    font-size: 15px;
  }
  .bl_editor h6 {
    font-size: 14px;
  }
  .bl_editor a {
    color: #245faf;
    text-decoration: underline;
  }
  .bl_editor ul {
    margin-bottom: 1em;
  }
  .bl_editor ul > li {
    margin-bottom: 0.5em;
  }
  .bl_editor ul > li::before {
    content: '●';
    position: absolute;
    margin-top: 0.9em;
    margin-left: -1.7em;
    font-size: 0.5em;
    color: #245faf;
  }
  .bl_editor ul li {
    position: relative;
    padding-left: 1em;
  }
  .bl_editor ul li > ul,
  .bl_editor ul li > ol {
    margin-top: 0.5em;
  }
  .bl_editor ul li:last-of-type {
    margin-bottom: 0;
  }
  .bl_editor ol {
    margin-bottom: 1em;
  }
  .bl_editor ol > li {
    margin-bottom: 0.5em;
    list-style: decimal;
  }
  .bl_editor ol li {
    position: relative;
    margin-left: 1.5em;
  }
  .bl_editor ol li:last-of-type {
    margin-bottom: 0;
  }
  .bl_editor ol li > ul,
  .bl_editor ol li > ol {
    margin-top: 0.5em;
  }
  .bl_editor table {
    margin: 1em 0;
    display: block;
    max-width: 96vw;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    border-collapse: collapse;
    border: 2px solid #f8f8f8;
  }
  .bl_editor table tbody {
    width: 100%;
    display: table;
  }
  .bl_editor table th,
  .bl_editor table td {
    padding: 1em;
    border-collapse: collapse;
    border: 1px solid #f8f8f8;
  }
  .bl_editor table th {
    text-align: left;
    background: #81898c;
  }
  .bl_editor table th {
    background: #dbe7f7;
    font-weight: 500;
  }
  .bl_editor dl dt {
    font-size: 12px;
    color: #98a6b5;
  }
  .bl_editor dl dd {
    margin-bottom: 1em;
    padding: 0.5em 0 1em;
    border-bottom: 1px solid #c6d3e1;
  }
  .bl_editor strong {
    font-weight: bold;
  }
  .bl_editor em {
    font-style: italic;
  }
  .bl_editor blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
} /*# sourceMappingURL=style.min.css.map */
